import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
// ---------------------------------------------
import Header from "../components/header"
import Layout from "../components/layout"
import LightboxLink from "../components/lightbox/link"
import Transition from "../components/transition"
import SEO from "../components/seo"

const Page = ({ data, location }) => {
  // check if data.craft.entries has a length
  if (!data.craft.entries.length) {
    // return a 404 page
    return (
      <Layout>
        <SEO title="404: Not found" />
        <Transition
          location={location}
          render={() => (
            <>
              <main className="head-offset">
                <div className="info">
                  <h1 class="centred">404: Not Found</h1>
                  <div className="info__row">
                    <p>
                      You just hit a route that doesn&#39;t exist... the
                      sadness.
                    </p>
                  </div>
                </div>
              </main>
            </>
          )}
        />
      </Layout>
    )
  }
  const { longText, title } = data.craft.entries[0]

  return (
    <Layout>
      <SEO title="Sustainability Policy" />
      <Transition
        location={location}
        render={handleLink => (
          <>
            <LightboxLink handleLink={handleLink} />
            <Header handleLink={handleLink} />
            <main className="head-offset sustainability">
              <div className="info">
                <h1 class="centred">{title}</h1>
                <div className="info__row">
                  <ReactMarkdown
                    source={longText}
                    escapeHtml={false}
                    className="copy-row"
                  />
                </div>
              </div>
            </main>
          </>
        )}
      />
    </Layout>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
  query PageQuery {
    craft {
      entries(type: "Pages", slug: "sustainability-policy") {
        ... on Craft_pages_pages_Entry {
          title
          slug
          id
          longText
        }
      }
    }
  }
`
