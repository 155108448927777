import React, { useEffect, useState, useRef } from "react";
import { Link as GatsbyLink, navigate } from "gatsby";
// import { useWhyDidYouUpdate } from '../helpers/useHooks';
// ---------------------------------------------
import Footer from "./footer";

const TransitionText = ({ children, hideTransitionText, speed = 800 }) => {

  let timer;
  const el = useRef();
  const [visible, setVisible] = useState(true);

  const hideText = () => {
    setVisible(false)
  }

  // NOTE: When mounted, fade in
  useEffect(() => {
    if (hideTransitionText) {
      el.current.addEventListener('transitionend', hideTransitionText)
    }
    timer = setTimeout(hideText, speed * 2)
    return () => {
      clearTimeout(timer)
      if (hideTransitionText) {
        el.current.removeEventListener('transitionend', hideTransitionText, false)
      }
    }
  }, [])

  return (
    <div className="pfix fs" ref={el} style={{
      opacity: visible ? 1 : 0,
      transition: `opacity ease-in-out ${speed}ms`,
      zIndex: 2000
    }}>
      {children}
    </div>
  )
}

const Transition = (props) => {

  // useWhyDidYouUpdate('Transition', props)

  const { intro, introKey, render, location, speed = 800, hideFooter } = props
  let timer;

  const [hasIntro, setHasIntro] = useState(() => {
    if (process.browser && sessionStorage) {
      if (intro) {
        const hasPlayed = sessionStorage.getItem(introKey);
        if (!hasPlayed) {
          sessionStorage.setItem(introKey, true)
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    } else {
      return false
    }
  });
  const [visible, setVisible] = useState(false);
  const [inProgress, setProgress] = useState(false);
  const [path, setPath] = useState(location.pathname);

  const handleLink = (to, event) => {
    !event || event.preventDefault();

    if (to !== path) {
      setVisible(false)
      setProgress(true)
      setPath(to)
    }
  }

  const changePage = () => {

    clearTimeout(timer);
    // setVisible(true)
    setProgress(false)
    navigate(path)
  }

  const showPage = () => {
    setVisible(true)
  }

  const hideTransitionText = () => {
    setHasIntro(false)
  }

  useEffect(() => {
    if (inProgress) {
      timer = setTimeout(changePage, speed);
    }
    return () => {
      clearTimeout(timer);
    }
  }, [path])

  // NOTE: When mounted, fade in
  useEffect(() => {
    timer = setTimeout(showPage, 200)
    return () => {
      clearTimeout(timer)
    }
  }, [hasIntro])

  return (
    <div id="transition" style={{
      opacity: visible ? 1 : 0,
      transition: `opacity ease-in-out ${speed}ms`
    }} key={`${introKey}-transition-wrapper`}>
      <div id="transition-intro">
        {hasIntro && (
          <TransitionText hideTransitionText={hideTransitionText}>
            {intro()}
          </TransitionText>
        )}
      </div>
      <div id="transition-content">
        {render(handleLink, hasIntro)}
      </div>
      {!hideFooter && <Footer />}
    </div>
  )
}

export default Transition

export const Link = ({ to, className, children, handleLink }) => handleLink ? (
  <GatsbyLink to={to} className={className} onClick={handleLink.bind(this, to)}>
    {children}
  </GatsbyLink>
) : (
  <GatsbyLink to={to} className={className}>
    {children}
  </GatsbyLink>
)
