import React, { useState } from "react";
// ------------------------------------
import { CloseIcon } from "../components/svgs";

const Footer = () => {

  const [showNewsletter, setShowNewsletter] = useState(false);

  const handleNewsletterClick = e => {
    e.preventDefault()
    setShowNewsletter(true)
  }

  return (
    <>
      <footer className="pfix b0 heading--4" style={{
        bottom: '1rem',
        left: '1rem'
      }}>
        <a href="https://instagram.com/minkmgmt" target="_blank" rel="noopener noreferrer">Instagram</a>
        <span>{", "}</span>
        <a href="https://instagram.com/minkmgmt" onClick={handleNewsletterClick}>Newsletter</a>
      </footer>
      {showNewsletter && (
        <Newsletter handleCloseClick={() => setShowNewsletter(false)} />
      )}
    </>

  )
}

const Newsletter = ({ handleCloseClick }) => (
  <div className="bio">
    <div className="bio__content">
      <div>
        <div>
          <span className="heading--4">Sign up to our Newsletter</span>
          <NewsletterForm />
        </div>
      </div>
    </div>
    <button className="bio__close" onClick={handleCloseClick}>
      <CloseIcon />
    </button>
  </div>
);

const NewsletterForm = () => {

  const [email, setEmail] = useState("")

  return (
    <div className="newsletter-form outerx2 heading--2">
      <form action="https://minkmgmt.us2.list-manage.com/subscribe/post?u=b6e17e1fd772eb0a348ee5dc8&amp;id=aafe8f2320" method="post" name="mc-embedded-subscribe-form" target="_blank" noValidate>
        <input type="email" value={email} name="EMAIL" placeholder={"Your Email"} onChange={e => setEmail(e.target.value)} />
        <div style={{
          position: 'absolute',
          left: -5000
        }} aria-hidden="true">
          <input type="text" name="b_b6e17e1fd772eb0a348ee5dc8_aafe8f2320" tabIndex="-1" readOnly value="" />
        </div>
        <input type="submit" name="subscribe" value="OK" />
      </form>
    </div>
  )
}

export default Footer
